@charset "UTF-8";

// SHARED IMPORTS
@import "shared";

// NPM / VENDORS
// @import "slick-carousel/slick/slick.scss";
// @import "vendors/fancybox";

// SCOPES
// Prefix: s
@import "front/scopes/browser";
@import "front/scopes/content";

// COMPONENTS
// Reutilisable components
// Prefix: c
@import "front/components/banner";
// @import "front/components/breadcrumb";
@import "front/components/button";
@import "front/components/card";
@import "front/components/connect";
@import "front/components/contact";
@import "front/components/dropdown";
@import "front/components/empty";
@import "front/components/fav";
@import "front/components/featured";
@import "front/components/features";
@import "front/components/figure";
@import "front/components/form";
@import "front/components/heading";
@import "front/components/highlight";
@import "front/components/jump";
@import "front/components/label";
@import "front/components/logo";
@import "front/components/menu";
@import "front/components/message";
@import "front/components/mynav";
@import "front/components/navigation";
@import "front/components/news";
@import "front/components/newsletter";
@import "front/components/pagination";
@import "front/components/popin";
@import "front/components/presenter";
@import "front/components/program";
@import "front/components/share";
@import "front/components/subtitle";
@import "front/components/tag";
@import "front/components/title";
@import "front/components/value";

// VIEWS
// Prefix: v
@import "front/views/about";
@import "front/views/flow";
@import "front/views/footer";
@import "front/views/guide";
@import "front/views/newscast";
@import "front/views/search";
@import "front/views/section";
// @import "front/views/slider";

// LAYOUT
// Layout elements
// Prefix: l
@import "front/layouts/container";
@import "front/layouts/grid";
@import "front/layouts/header";
@import "front/layouts/main";
@import "front/layouts/overlay";
@import "front/layouts/page";
@import "front/layouts/recent";

// UTILITIES
// Utility classes
// Prefix: u
@import "front/utilities/colors";
@import "front/utilities/hidden";
@import "front/utilities/text";

// TAC / TarteAuCitron / GDPR
//@import "tarteaucitronjs/css/tarteaucitron";
@import "vendors/_luna_tac";

// A11y
// Accessibility tool
// @import "a11y.css/sass/a11y-fr";


// HIDE
.c-button,
.c-contact__head,
.c-dropdown,
.c-empty,
.v-flow__play,
.v-flow__action,
.v-flow__live,
.v-footer__head,
.v-newscast__aside,
.v-section__more,
.v-section__link,
.v-slider .slick-slide[tabindex="-1"] ~ .slick-slide,
.v-slider .slick-arrow {
  display: none !important;
}

// RE-INIT SIZES
.c-jump__inner,
.v-flow__aside {
  width: auto !important;
  min-width: 0 !important;
  height: auto !important;
  min-height: 0 !important;
}

// RE-INIT MARGINS & PADDINGS
.c-contact__body,
.v-flow__content,
.v-footer__container {
  margin: 0 !important;
  padding: 0 !important;
}

// OTHERS...
.v-slider .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100% !important;

  .slick-slide { width: 50% !important; }
}

.c-breadcrumb__items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

  .c-breadcrumb__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &:not(:first-child) {

      &:before {
        content: '>';
        display: block;
        @include rem(margin-right, 5px);
        @include rem(margin-left, 5px);
      }
    }
  }

.c-features--branded:before { display: none }
.c-features__item { width: 100% !important; }
